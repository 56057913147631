import React, { useEffect, useState } from 'react';

export const useMobileView = () => {
    const [isInMobileView, setIsMobileView] = useState(false);

    const checkIsMobileView = React.useCallback(() => {
        const mobileDevicesRegexp = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/;
        const mobileViewBreakpoint = 768;
        const isMobileView = mobileDevicesRegexp.test(navigator.userAgent) || window.innerWidth <= mobileViewBreakpoint;

        setIsMobileView(isMobileView);
    }, []);

    useEffect(() => {
        checkIsMobileView();

        window.addEventListener('resize', checkIsMobileView);

        return () => {
            window.removeEventListener('resize', checkIsMobileView);
        };
    }, [isInMobileView]);

    return isInMobileView;
};
