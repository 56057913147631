import React from 'react';
import classNames from 'classnames';

export interface IContainer {
    children?: React.ReactNode;
    classes?: string;
}

const Container: React.FC<IContainer> = (props) => (
    <div className={classNames('container', props.classes)}>{props.children}</div>
);

export default Container;
