export const getAvailableDomains = (): Record<string, string> => {
    const domains = process.env.NEXT_PUBLIC_DOMAINS;

    if (!domains) {
        throw new Error('NEXT_PUBLIC_DOMAINS: No domains provided!');
    }

    return JSON.parse(domains);
};

export const getDomainUrlByLang = (lang: string): string => {
    const domain = getAvailableDomains()[lang];

    if (!domain) {
        throw new Error(`Domain for language ${lang} not found`);
    }

    return domain;
};

export const getSelectOptions = () => Object.keys(getAvailableDomains());
