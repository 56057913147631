import { TFunction } from 'i18next';
import { translate } from '@/translations/static/staticTranslations';

// TODO: replace this mocks with BE response
import fbIcon from '@/assets/images/facebook.svg';
import instaIcon from '@/assets/images/instagram.svg';
import ytIcon from '@/assets/images/youTube.svg';
import ttIcon from '@/assets/images/tikTok.svg';

export interface IUpperFooterLinks {
    title: string;
    url: string;
}

export interface IUpperFooterIcons {
    iconPath: string;
    link: {
        noFollow: boolean;
        targetBlank: boolean;
        url: string;
        name: string;
    };
}

export interface IUpperFooterElements {
    label: string;
    items: IUpperFooterLinks[] | null;
    icons: IUpperFooterIcons[] | null;
}

// NEW LANGUAGE
export const getLanguageByDomainLabel = (domainLabel: string) =>
    ({
        pl: 'pl',
        sk: 'sk',
        cz: 'cs',
        eu: 'en',
    }[domainLabel]);

export const upperFooterLinks = (t: TFunction, language: string) => {
    const upperFooterLinksArray: IUpperFooterElements[] = [
        {
            label: t('translations.footerLinksTitle.imageBank'),
            items: [
                {
                    title: t('translations.footerLinksLabel.howItWorks'),
                    url: translate(language, 'routes.howItWorks'),
                },
                {
                    title: t('translations.footerLinksLabel.currentProjects'),
                    url: translate(language, 'currentProjectsLinks.seeMore'),
                },
                {
                    title: t('translations.footerLinksLabel.archivalContests'),
                    url: translate(language, 'routes.archivedContests'),
                },
                {
                    title: t('translations.footerLinksLabel.myAccount'),
                    url: translate(language, 'routes.account'),
                },
            ],
            icons: [],
        },
        {
            label: t('translations.footerLinksTitle.info'),
            items: [
                {
                    title: t('translations.footerLinksLabel.terms'),
                    url: t('translations.footerLinksLabel.termsHref'),
                },
                {
                    title: t('translations.footerLinksLabel.rodo'),
                    url: t('translations.footerLinksLabel.rodoHref'),
                },
            ],
            icons: [],
        },
        {
            label: t('translations.footerLinksTitle.contact'),
            items: [
                {
                    title: t('translations.footerLinksLabel.mail'),
                    url: `mailto:${t('translations.footerLinksLabel.mail')}`,
                },
                {
                    title: t('translations.footerLinksLabel.contact'),
                    url: `${translate(language, 'routes.howItWorksContact')}`,
                },
            ],
            icons: [],
        },
        {
            label: t('translations.footerLinksTitle.socials'),
            items: [],
            icons: [
                {
                    iconPath: fbIcon,
                    link: {
                        noFollow: false,
                        targetBlank: true,
                        url: t('translations.footerLinkSocials.facebook'),
                        name: '',
                    },
                },
                {
                    iconPath: instaIcon,
                    link: {
                        noFollow: false,
                        targetBlank: true,
                        url: t('translations.footerLinkSocials.instagram'),
                        name: '',
                    },
                },
                {
                    iconPath: ytIcon,
                    link: {
                        noFollow: false,
                        targetBlank: true,
                        url: t('translations.footerLinkSocials.youtube'),
                        name: '',
                    },
                },
                {
                    iconPath: ttIcon,
                    link: {
                        noFollow: false,
                        targetBlank: true,
                        url: t('translations.footerLinkSocials.tiktok'),
                        name: '',
                    },
                },
            ],
        },
    ];

    return upperFooterLinksArray;
};
