export default {
    routes: {
        home: '/',
        login: '/login',
        howItWorks: '/how-it-works',
        howItWorksContact: '/how-it-works#contact',
        account: '/my-account',
        accountSendProjects: '/my-account/sent-projects',
        accountContestsList: '/my-account/list-of-competitions',
        accountContact: '/my-account/contact',
        contest: '/contest',
        archivedContests: '/archived-contests',
        resetPassword: '/reset-password',
        notFound: '/page-not-found',
        internalError: '/internal-error',
        terms: '/a/terms-of-website-use',
        rodo: '/a/privacy-policy',
    },
    currentProjectsLinks: {
        seeMore: 'https://wearmedicine.com/l/art-series',
    },
};
