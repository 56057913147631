'use client';

import React from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import styles from '@/components/footer/Footer.module.scss';
import { useTranslation } from '@app/i18n/useTranslationClient';
import { getAvailableDomains } from '@/components/header/lang-switcher/langSwitcher.helper';
import { getLanguageByDomainLabel } from '@/components/footer/footer.helper';

const FooterMarkets = () => {
    const {
        i18n: { language },
    } = useTranslation();

    const availableDomains = getAvailableDomains();

    if (!availableDomains) {
        return null;
    }

    return (
        <>
            {Object.keys(availableDomains).map((domainLabel, index) => (
                <Link
                    href={availableDomains[domainLabel]}
                    className={classNames(styles.bottomWrapperContentLangElement, {
                        [styles.bottomWrapperContentLangElementActive]:
                            language === getLanguageByDomainLabel(domainLabel.toLowerCase()),
                    })}
                    key={index}>
                    {domainLabel}
                </Link>
            ))}
        </>
    );
};

export default FooterMarkets;
