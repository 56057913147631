import React from 'react';
import { Accordion, AccordionIcon } from '@/components/accordion/Accordion';
import styles from '@/components/footer/Footer.module.scss';
import Link from 'next/link';
import { IFooterElement } from '@/components/footer/Footer';

const FooterAccordion: React.FC<IFooterElement> = ({ element, index }) => (
    <Accordion
        iconType={AccordionIcon.ARROW_BLACK}
        classes={{
            wrapper: styles.wrapperContent,
            title: styles.wrapperContentTitle,
        }}
        key={index}
        title={element.label.toUpperCase()}>
        {element.items?.map((el, index) => (
            <Link href={el.url} key={index} className={styles.wrapperContentElements}>
                {el.title}
            </Link>
        ))}
    </Accordion>
);

export default FooterAccordion;
