import React from 'react';
import styles from '@/components/footer/Footer.module.scss';
import Link from 'next/link';
import { IFooterElement } from '@/components/footer/Footer';

const FooterLinksElement: React.FC<IFooterElement> = ({ element, index }) => (
    <div className={styles.wrapperContent} key={index}>
        <span className={styles.wrapperContentTitle}>{element.label.toUpperCase()}</span>
        {element.items?.map((el, index) => (
            <Link href={el.url} key={index} className={styles.wrapperContentElements}>
                {el.title}
            </Link>
        ))}
    </div>
);

export default FooterLinksElement;
