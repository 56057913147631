import { routes } from '@/utils/routes';
import { IContestListItem, IContests } from '@/components/contests/contests.interfaces';

export const getPropValue = (object: any, path: string) =>
    path
        .replace(/\[/g, '.')
        .replace(/\]/g, '')
        .split('.')
        .reduce((o, k) => (o || {})[k], object);

export const compareListItems = (firstItem: any, secondItem: any) => {
    const first = typeof firstItem === 'string' ? firstItem.toLowerCase() : firstItem;
    const second = typeof secondItem === 'string' ? secondItem.toLowerCase() : secondItem;

    if (first < second) {
        return -1;
    }

    if (first > second) {
        return 1;
    }

    return 0;
};

export const contestPath = (slug: string) => `${routes.CURRENT_CONTEST}/${slug}`;

export const showContests = (arr: IContestListItem[] | IContests[]) => arr?.length > 0;

export const scrollToSection = (id: string, scrollOffsetTop: number) => {
    const element = document.getElementById(id);

    if (element) {
        window.scrollTo({
            top: element.offsetTop - scrollOffsetTop,
            behavior: 'smooth',
        });
    }
};
