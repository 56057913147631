import React from 'react';
import classNames from 'classnames';
import styles from '@/components/footer/Footer.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import { IFooterElement } from '@/components/footer/Footer';

const FooterSocialsElement: React.FC<IFooterElement> = ({ element, index }) => (
    <div className={classNames(styles.wrapperContent)} key={index}>
        <span className={classNames(styles.wrapperContentTitle, styles.wrapperContentTitleSocials)}>
            {element.label.toUpperCase()}
        </span>
        <div className={styles.wrapperContentSocialIcons}>
            {element.icons?.map(
                (el, index) =>
                    el.link.url && (
                        <Link
                            key={index}
                            href={el.link.url}
                            className={classNames(styles.wrapperContentElements, styles.wrapperContentElementsIcon)}>
                            <Image src={el.iconPath} alt={el.link.name} />
                        </Link>
                    )
            )}
        </div>
    </div>
);

export default FooterSocialsElement;
