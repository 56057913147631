import { getPropValue } from '@/utils/utils';
import { Language, LocaleByLanguage } from '@/services/language.service';

interface ITranslationData {
    [name: string]: string | object;
}

const getTranslationFile = (locale: string) => require(`./langs/${locale}.ts`).default;

const translationData: ITranslationData = {};

Object.values(Language).forEach((lang) => {
    translationData[lang] = getTranslationFile(LocaleByLanguage[lang]);
});

// export const translate = <T extends boolean>(
//     lang: string,
//     path: string,
//     returnPath: T = true as T
// ): T extends true ? string : boolean => {
//     const translation = getPropValue(translationData, `${lang}.${path}`);
//
//     if (translation) {
//         return (translation) as T extends true ? string : boolean;
//     } else {
//         return (returnPath ? path : false) as T extends true ? string : boolean;
//     }
// };

export const translate = (lang: string, path: string): string =>
    getPropValue(translationData, `${lang}.${path}`) || path;

export const translateAlternative = (lang: string, path: string): string | boolean =>
    getPropValue(translationData, `${lang}.${path}`) || false;
