const routes = {
    HOME: '/',
    LOGIN: '/login',
    HOW_IT_WORKS: '/how-it-works',
    ACCOUNT: '/account',
    ACCOUNT_SENT_PROJECTS: '/account/account-projects-list',
    ACCOUNT_CONTESTS_LIST: '/account/account-contests-list',
    CONTEST: '/contest/:slug',
    CURRENT_CONTEST: '/contest',
    ARCHIVED_CONTESTS: '/archived-contests',
    ACCOUNT_CONTACT: '/account/account-contact',
    RESET_PASSWORD: '/reset-password',
    NOT_FOUND: '/not-found',
    INTERNAL_ERROR: '/internal-error',
    STATIC_PAGE: '/static-page/:slug',
};

// NEW LANGUAGE
const rewritesPL = [
    {
        source: '/logowanie',
        destination: routes.LOGIN,
    },
    {
        source: '/moje-konto',
        destination: routes.ACCOUNT,
    },
    {
        source: '/moje-konto/wyslane-projekty',
        destination: routes.ACCOUNT_SENT_PROJECTS,
    },
    {
        source: '/moje-konto/lista-konkursow',
        destination: routes.ACCOUNT_CONTESTS_LIST,
    },
    {
        source: '/konkurs/:slug',
        destination: routes.CONTEST,
    },
    {
        source: '/konkurs',
        destination: routes.CURRENT_CONTEST,
    },
    {
        source: '/archiwalne-konkursy',
        destination: routes.ARCHIVED_CONTESTS,
    },
    {
        source: '/moje-konto/kontakt',
        destination: routes.ACCOUNT_CONTACT,
    },
    {
        source: '/jak-to-dziala',
        destination: routes.HOW_IT_WORKS,
    },
    {
        source: '/a/:slug',
        destination: routes.STATIC_PAGE,
    },
    {
        source: '/reset-password',
        destination: routes.RESET_PASSWORD,
    },
    {
        source: '/blad-wewnetrzny',
        destination: routes.INTERNAL_ERROR,
    },
    {
        source: '/strona-nie-istnieje',
        destination: routes.NOT_FOUND,
    },
];

const rewritesDefault = [
    {
        source: '/login',
        destination: routes.LOGIN,
    },
    {
        source: '/my-account',
        destination: routes.ACCOUNT,
    },
    {
        source: '/my-account/sent-projects',
        destination: routes.ACCOUNT_SENT_PROJECTS,
    },
    {
        source: '/my-account/list-of-competitions',
        destination: routes.ACCOUNT_CONTESTS_LIST,
    },
    {
        source: '/contest/:slug',
        destination: routes.CONTEST,
    },
    {
        source: '/contest',
        destination: routes.CURRENT_CONTEST,
    },
    {
        source: '/archived-contests',
        destination: routes.ARCHIVED_CONTESTS,
    },
    {
        source: '/my-account/contact',
        destination: routes.ACCOUNT_CONTACT,
    },
    {
        source: '/how-it-works',
        destination: routes.HOW_IT_WORKS,
    },
    {
        source: '/reset-password',
        destination: routes.RESET_PASSWORD,
    },
    {
        source: '/internal-error',
        destination: routes.INTERNAL_ERROR,
    },
    {
        source: '/page-not-found',
        destination: routes.NOT_FOUND,
    },
    {
        source: '/a/:slug',
        destination: routes.STATIC_PAGE,
    },
];

exports.routes = routes;

// NEW LANGUAGE
exports.rewritesPL = rewritesPL;
exports.rewritesDefault = rewritesDefault;
