export default {
    routes: {
        home: '/',
        login: '/logowanie',
        howItWorks: '/jak-to-dziala',
        howItWorksContact: '/jak-to-dziala#contact',
        account: '/moje-konto',
        accountSendProjects: '/moje-konto/wyslane-projekty',
        accountContestsList: '/moje-konto/lista-konkursow',
        accountContact: '/moje-konto/kontakt',
        contest: '/konkurs',
        archivedContests: '/archiwalne-konkursy',
        resetPassword: '/reset-password',
        notFound: '/strona-nie-istnieje',
        internalError: '/blad-wewnetrzny',
        terms: '/a/regulamin',
        rodo: '/a/polityka-prywatnosci',
    },
    currentProjectsLinks: {
        seeMore: 'https://wearmedicine.com/l/art-series',
    },
};
