'use client';

import React from 'react';
import FooterAccordion from '@/components/footer/footer-accordion/FooterAccordion';
import FooterLinksElement from '@/components/footer/footer-links-element/FooterLinksElement';
import { IFooterElement } from '@/components/footer/Footer';
import { useMobileView } from '@/hooks/useIsInMobileView';
import FooterSocialsElement from '@/components/footer/footer-social-element/FooterSocialsElement';

const FooterUpperElement: React.FC<IFooterElement> = ({ element, index }) => {
    const isInMobileView = useMobileView();

    if (element.items?.length) {
        return isInMobileView ? (
            <FooterAccordion element={element} index={index} />
        ) : (
            <FooterLinksElement element={element} index={index} />
        );
    }

    if (element.icons?.length) {
        return <FooterSocialsElement element={element} index={index} />;
    }

    return <></>;
};

export default FooterUpperElement;
