'use client';

import React from 'react';
import styles from './Footer.module.scss';
import Container from '@/components/Container/Container';
import { IUpperFooterElements, upperFooterLinks } from '@/components/footer/footer.helper';
import FooterMarkets from '@/components/footer/footer-markets/FooterMarkets';
import FooterUpperElement from '@/components/footer/footer-upper-element/FooterUpperElement';
import { useTranslation } from '@app/i18n/useTranslationClient';

export interface IFooterElement {
    element: IUpperFooterElements;
    index: number;
}

const Footer: React.FC = () => {
    const {
        t,
        i18n: { language },
    } = useTranslation();

    return (
        <>
            <div className={styles.wrapper}>
                <Container classes={styles.footerContainer}>
                    <div className={styles.wrapperUpperContainer}>
                        {upperFooterLinks(t, language).map((element, index) => (
                            <FooterUpperElement key={index} element={element} index={index} />
                        ))}
                    </div>
                </Container>
            </div>
            <div className={styles.bottomWrapper}>
                <Container classes={styles.footerContainer}>
                    <div className={styles.bottomWrapperContent}>
                        <div className={styles.bottomWrapperContentShopInfo}>{t('translations.footer.shopInfo')}</div>
                        <div className={styles.bottomWrapperContentLang}>
                            <FooterMarkets />
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default Footer;
